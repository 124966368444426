import React from "react";
import { connect } from "react-redux";
import {
  END_CONVERSATION,
  FORWARD_CONVERSATION,
  CHANGE_POOL_CONVERSATION,
  SHOW_MESSAGE,
  SHOW_QUICK_ANSWER,
  SHOW_QUICK_FILES,
  SHOW_MORE_INPUT_OPTIONS,
  FORWARD_AND_CHANGE_POOL_CONVERSATION,
  SHOW_LOCATION_SHARING,
  SHOW_CHANGE_AGENT
} from "../store/popupTypes";
import { makeStyles } from "@material-ui/core/styles";
import { SET_POPUP } from "../store/actionTypes";
import { Dialog } from "@material-ui/core";
import EndConversationPopup from "./endConversationPopup";
import ForwardConversationPopup from "./forwardConversationPopup";
import ChangePoolConversationPopup from "./changePoolConversationPopup";
import ForwardAndChangePoolPopup from "./forwardAndChangePoolPopup";
import QuickAnswerPopup from "./quickAnswerPopup"
import QuickFilesPopup from "./quickFilesPopup"
import MoreInputOptions from "./showMoreInputOptionsPopup"
import ShowMessagePopup from "./showMessagePopup";
import ShowLocationSharingPopup from "./showLocationSharingPopup"
import ChangeAgentPopup from "./changeAgentPopup"

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: "80vh",
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1)
    },
    quickAnswerModalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: "80vh",
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      maxWidth: "300px",
      width: "300px",
      height: "55vh"
    },
    assignConversationModalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: "80vh",
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      maxWidth: "300px",
      width: "300px",
      height: "65vh"
    },
    endConversationModalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: "80vh",
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      maxWidth: "300px",
      width: "300px",
      height: "65vh"
    },
    quickFilesModalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: "80vh",
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      maxWidth: "100%",
      width: "100%",
      height: "65vh"
    },
    locationSharingModalContent: {
      display: "grid",
      minHeight: 100,
      maxHeight: 750,
      maxWidth: 300,
      overflowY: "overlay",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      width: "100%",
      height: "100%"
    },
  };
});

const Popups = (props) => {
  const classes = useStyles();
  const { popup, handleClose } = props;
  if (popup === null) {
    return null;
  }
  let content = null;
  switch (popup.type) {
    case END_CONVERSATION:
      content = (
        <div className={classes.endConversationModalContent}>
          <EndConversationPopup {...popup.props} handleClose={handleClose} />
        </div>
      );
      break;
    case FORWARD_CONVERSATION:
      content = (
        <div className={classes.modalContent}>
          <ForwardConversationPopup {...popup.props} handleClose={handleClose} />
        </div>
      );
      break;
    case CHANGE_POOL_CONVERSATION:
      content = (
        <div className={classes.modalContent}>
          <ChangePoolConversationPopup
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      );
      break;
    case SHOW_QUICK_ANSWER:
      content = (
        <div className={classes.quickAnswerModalContent}>
          <QuickAnswerPopup
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      )
      break;
    case SHOW_QUICK_FILES:
      content = (
        <div className={classes.quickFilesModalContent}>
          <QuickFilesPopup
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      )
      break;
    case SHOW_LOCATION_SHARING:
      content = (
        <div className={classes.locationSharingModalContent}>
          <ShowLocationSharingPopup
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      )
      break;
    case SHOW_MORE_INPUT_OPTIONS:
      content = (
        <div className={classes.modalContent}>
          <MoreInputOptions
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      )
      break;
    case SHOW_CHANGE_AGENT:
      content = (
        <div className={classes.modalContent}>
          <ChangeAgentPopup
            {...popup.props}
            handleClose={handleClose}
          />
        </div>
      )
      break;
    case SHOW_MESSAGE:
      content = (
        <div className={classes.modalContent}>
          < ShowMessagePopup {...popup.props} handleClose={handleClose} />
        </div>
      )
      break;
    case FORWARD_AND_CHANGE_POOL_CONVERSATION:
      content = (
        <div className={classes.assignConversationModalContent}>
          < ForwardAndChangePoolPopup {...popup.props} handleClose={handleClose} />
        </div>
      )
      break;
    default:
      content = <div className={classes.modalContent}></div>
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={classes.modal}
      disableScrollLock={true}
    >
      {content}
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  popup: store.popup,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => {
    dispatch({
      type: SET_POPUP,
      args: null,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
