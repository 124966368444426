import getAxios from "./api";
import store from "../store";
import { SET_AGENT_QUICK_FILES } from "../store/actionTypes";

const getAgentQuickFiles = () => {
  console.debug("getAgentQuickFiles");
  return getAxios()
    .get(`/AgentQuickFiles`)
    .then((result) => {
      if (result.data.content) {
        return store.dispatch({
          type: SET_AGENT_QUICK_FILES,
          args: result.data.content,
        });
      } else {
        return store.dispatch({ type: SET_AGENT_QUICK_FILES, args: {} });
      }
    });
};
export default getAgentQuickFiles;
