import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux"
import Autocomplete from '@material-ui/lab/Autocomplete';
import SpinLoading from "../components/spinLoading";
import sendQuickFileService from "../services/sendQuickFile";
import getQuickFiles from "../services/getQuickFiles";
import {
  TextField,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  List,
  Container,
} from "@material-ui/core";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import NoteAddSharpIcon from "@material-ui/icons/NoteAddSharp";

const useStyles = makeStyles((theme) => {
  return {
    searchbar: {
      width: "100%",
      marginTop: "30px"
    },
    quickFilesList: {
      display: "block",
      widows: "100%",
    },
    listItems: {
      display: "block",
    },
    iconContainerStyle: {
      textAlign: "center",
      width: "250px",
      height: "120px",
      background: "none",
      borderRadius: "0",
      margin: "auto",
    },
    iconStyle: {
      fontSize: "120px",
      color: "#424242",
    },
    categorybar: {
      marginTop: "15px"
    }
  };
});

const sendQuickFile = (file, convID) => {

  if (!file) {
    return;
  }
  let fileType = undefined;
  var ext = file.name.split(".").pop().toLowerCase();
  switch (ext) {
    case "png":
    case "jpg":
    case "jpeg":
      fileType = "IMAGE"
      break;

    case "xlsx":
    case "xls":
    case "doc":
    case "docx":
    case "pdf":
      fileType = "FILE"
      break;

    default:
      alert("WARNING: wrong file format.");
      return;
  }
  sendQuickFileService(file, convID, fileType);
};

const QuickFilesPopup = (props) => {

  const classes = useStyles();
  const { conv, token, quickFileCategory, quickFiles, convID } = props;
  const [quickFilesUrls, setQuickFilesUrls] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    let quickFilesUrls = [];

    Object.keys(quickFiles).map((item) => {
      let obj = quickFiles[item];
      Promise.resolve(getQuickFiles(obj)).then(function (value) {
        quickFilesUrls.push(value);
      });
      return true;
    });
    setQuickFilesUrls(quickFilesUrls);
  }, []);

  if (!conv || !token) {
    return <SpinLoading />;
  } else {
    let files = [];
    let quickFilesList = [];

    Object.keys(quickFiles).map((item) => {
      let obj = quickFiles[item];
      quickFilesList.push(obj);
      return true;
    });
    let content;
    quickFilesList.forEach((i, v) => {
      if (
        search === undefined ||
        search.length === 0 ||
        (quickFilesList[v].comment !== undefined &&
          quickFilesList[v].comment.toLocaleLowerCase().indexOf(search) > -1) ||
        (quickFilesList[v].name !== undefined &&
          quickFilesList[v].name.toLocaleLowerCase().indexOf(search) > -1)
      ) {
        if (category.length === 0 || category === quickFilesList[v].categoryID) {

          let url = "";
          if (quickFilesUrls.length > 0) {

            for (let i = 0; i < quickFilesUrls.length; i++) {
              let name = quickFilesUrls[i].split("?")[0].split("/")[5];
              let fileName = encodeURIComponent(quickFilesList[v].name.trim())
              if (name === fileName) {
                url = quickFilesUrls[i] + "?token=" + token;
              }
            }

            let fileType = url.split("?")[0].split(".").reverse()[0];

            switch (fileType) {
              case "docx":
              case "pdf":
              case "doc":
                content = (
                  <Avatar className={classes.iconContainerStyle}>
                    <DescriptionSharpIcon className={classes.iconStyle} />
                  </Avatar>
                );
                break;
              case "xlsx":
              case "xls":
              case "csv":
                content = (
                  <Avatar className={classes.iconContainerStyle}>
                    <NoteAddSharpIcon className={classes.iconStyle} />
                  </Avatar>
                );
                break;
              default:
                content = <Avatar className={classes.iconContainerStyle} alt="" src={url} />;
                break;
            }
          }
          files.push(
            <ListItem
              button
              className={classes.listItems}
              key={"item" + quickFilesList[v].id}
              onClick={() => sendQuickFile(quickFilesList[v], convID)}
            >
              <ListItemText primary={quickFilesList[v].name} />
              <ListItemAvatar>{content}</ListItemAvatar>
              <ListItemText secondary={quickFilesList[v].comment} />
            </ListItem>
          );
        }
      }
    });
    return (
      <Container>
        <TextField
          label={"Search"}
          variant="outlined"
          name="category"
          fullWidth
          className={classes.searchbar}
          autoFocus
          onChange={(event) => setSearch(event.target.value)}
        />
        <Autocomplete
          id="categories"
          options={quickFileCategory}
          getOptionLabel={(option) => option.name}
          fullWidth
          renderInput={(params) => <TextField label={"Category Name"} {...params} style={{ marginTop: '5px' }} variant="outlined" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setCategory(newValue.id)
            }
          }}
          className={classes.categorybar}
        />
        <List className={classes.quickFilesList}>{files}</List>
      </Container>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  handleClose: ownProps.handleClose,
  conv: store.conversations[ownProps.convID],
  token: store.token,
  quickFiles: store.quickFiles,
  quickFileCategory: store.quickFileCategory,
  convID: ownProps.convID,
})

export default connect(mapStateToProps)(QuickFilesPopup)
