import React from "react"
import GoogleMap from "../components/googleMap"
import { SET_POPUP } from "../store/actionTypes"
import { SHOW_MESSAGE } from "../store/popupTypes"
import { connect } from "react-redux"


const ShowLocationSharingPopup = (props) => {
    const { handleClose } = props

    return (
        <GoogleMap handleClose={handleClose} />
    )
}
const mapStateToProps = (store, ownProps) => ({
    handleClose: ownProps.handleClose,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    showMessage: (message) => {
        dispatch({
            type: SET_POPUP,
            args: {
                type: SHOW_MESSAGE,
                props: { content: message }
            }
        })
    }
})

export default connect(mapDispatchToProps, mapStateToProps)(ShowLocationSharingPopup)
