import getAxios from "./api";
import store from "../store";
import { SET_AGENTS } from "../store/actionTypes";

const getAgents = () => {
  console.debug('getAgents');
  return getAxios()
    .get(`/Agent`)
    .then((result) => {
      if (result.data.content) {
        let agents = {};
        result.data.content.forEach(a=>{
          agents[a.id] = a;
        })
        return store.dispatch({
          type: SET_AGENTS,
          args: agents,
        });
      } else {
        return store.dispatch({ type: SET_AGENTS, args: {} });
      }
    });
};
export default getAgents;
