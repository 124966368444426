import React from "react";
import { connect } from "react-redux";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SET_NOTIFICATION } from "../store/actionTypes";

const Popups = (props) => {
  const { notification } = props;
  if (!notification) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={true}
      autoHideDuration={6000}
      onClose={props.handleClose}
      message={notification.payload}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

const mapStateToProps = (store) => ({
  notification: store.notification,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => {
    dispatch({
      type: SET_NOTIFICATION,
      args: null,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
