import getAxios from "./api";
import store from "../store";
import { SET_AGENT_CONVERSATION_SUMMARY } from "../store/actionTypes";

const getAgentConversationSummary = () => {
    console.debug("getAgentConversationSummary");

    return getAxios().get('/AgentConversationSummary').then((result) => {
        if (result.data.content) {
            return store.dispatch({
                type: SET_AGENT_CONVERSATION_SUMMARY,
                args: result.data.content,
            })
        } else {
            return store.dispatch({
                type: SET_AGENT_CONVERSATION_SUMMARY,
                args: {}
            })
        }
    })
};

export default getAgentConversationSummary;
