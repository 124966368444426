import getAxios from "./api";
import login from "./login";

const changePassword = (username, currentPass, newPass) => {
  return getAxios()
    .post("/Password", { username, currentPass, newPass })
    .then((result) => {
      console.debug("changePassword", result.data);
      login();
      if (result.data.error) {
        throw new Error(result.data.error);
      } else {
        return true;
      }
    });
};
export default changePassword;
