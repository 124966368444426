import store from "../store";
import getAgents from "../services/getAgents";
import getOpenConversations from "../services/getOpenConversations";
import { AGENT_SOCKET_PING_INTERVAL, AGENT_SOCKET_CONNECTED_INTERVAL } from "../const";

class AgentListener {
  constructor() {
    const { token, backend, secureBackend } = store.getState();
    const protocol = secureBackend ? "wss://" : "ws://";
    this.endpoint = `${protocol}${backend}/Socket/Agent?token=${token}`;
    console.debug("Agent Listener constructor", this.endpoint);
  }
  listen() {
    this.websocket = new WebSocket(this.endpoint);
    console.debug("Agent Listener listen", this.endpoint);
    this.websocket.addEventListener("message", this.onMessage.bind(this));
    this.interval = setInterval(() => {
      this.websocket.send("ping");
    }, AGENT_SOCKET_PING_INTERVAL);
    this.interval = setInterval(() => {
      this.websocket.send("connected");
    }, AGENT_SOCKET_CONNECTED_INTERVAL);
  }
  onMessage(event) {
    let { data } = event;
    console.debug("Agent Listener onMessage", this.endpoint, { data });
    switch (data) {
      case "ping":
        break;
      case "connected":
        break;
      case "REFRESH_AGENT_LIST":
        getAgents();
        break;
      case "REFRESH_AGENT_CONVERSATIONS":
        getOpenConversations();
        break;
      default:
        break;
    }
    return;
  }
  stop() {
    this.websocket.close();
    clearInterval(this.interval);
    console.debug("Agent Listener stop", this.endpoint);
  }
}
export default AgentListener;
