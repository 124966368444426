import getAxios from "./api";
import store from "../store";
import logout from "../services/logout"
import { SET_OPEN_CONVERSATIONS } from "../store/actionTypes";

const getOpenConversations = () => {
  console.debug('getOpenConversations');
  let currentAgent = store.getState().currentAgent;
  return getAxios()
    .get(`/OpenConversation?currentAgent=${currentAgent}`)
    .then((result) => {
      let map = {};
      if (result.data.content !== undefined) {
        result.data.content.forEach(conv => {
          map[conv[0].id] = conv[0];
        })
      } else logout()

      if (result.data.content) {
        return store.dispatch({
          type: SET_OPEN_CONVERSATIONS,
          args: map,
        });
      } else {
        return store.dispatch({ type: SET_OPEN_CONVERSATIONS, args: null });
      }
    });
};
export default getOpenConversations;
