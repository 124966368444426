import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoginForm from "../pages/loginForm";
import ForgotPassword from "../pages/forgotPassword";
import ConnectionSettings from "../pages/connectionSettings";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: 30,
  },
}));

const LoginRouter = () => {
  const classes = useStyles();
  return (
    <Router>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Switch>
          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/connectionSettings">
            <ConnectionSettings />
          </Route>
          <Route>
            <LoginForm />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
};
export default LoginRouter;
