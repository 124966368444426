import React, { useState } from "react"
import { useGoogleMaps } from "react-hook-google-maps"
import { connect } from "react-redux"
import { SET_POPUP } from "../store/actionTypes"
import { SHOW_MESSAGE } from "../store/popupTypes"
import { useTranslation } from "react-i18next"
import sendMessage from "../services/sendMessage";
import { LOCATION } from "../subPages/conversation/dialog/messageList/message/types";
import {
    DialogTitle,
    DialogActions,
    Button,
} from "@material-ui/core"

const geo = navigator.geolocation
const center = { lat: 41.112557, lng: 29.019685 }

const GoogleMap = (props) => {
    const [liveLocation, setLiveLocation] = useState()
    const { handleClose, conv } = props
    const { t } = useTranslation("popup");
    const { ref, map, google } = useGoogleMaps(
        "AIzaSyCZPbTsZWHl85nNZ9yD6kEGbx68MT9vC5g",
        {
            zoom: 14,
            center: center,
            mapTypeId: "roadmap",
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false
        }
    )

    let convID = Object.values(conv)[0].id

    if (map) {
        geo.getCurrentPosition((position) => {
            map.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })

            let marker = new google.maps.Marker({
                position: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                map: map
            });

            google.maps.event.addListener(map, 'center_changed', function () {
                const centerMarker = map.getCenter()
                marker.setPosition(centerMarker)
                setLiveLocation(marker.position.lat() + "," + marker.position.lng())
            })
        })
    }


    return (
        <>
            <DialogTitle>{t("shareLocation")}</DialogTitle>
            <div ref={ref} style={{ width: 300, height: 300 }} />
            <DialogActions>
                <Button color="primary"
                    onClick={() =>
                        handleClose()}
                >
                    {t("close")}
                </Button>
                <Button color="primary"
                    onClick={() => {
                        if (convID && liveLocation !== null) {
                            sendMessage(convID, liveLocation, LOCATION)
                            handleClose()
                        }
                    }}
                >
                    {t("shareLocation")}
                </Button>
            </DialogActions>
        </>
    )
}

const mapStateToProps = (store, ownProps) => ({
    handleClose: ownProps.handleClose,
    conv: store.conversations,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    showMessage: (message, convID, conv) => {
        dispatch({
            type: SET_POPUP,
            args: {
                type: SHOW_MESSAGE,
                props: { content: message, convID: convID, conv: conv }
            }
        })
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMap)