import getAxios from "./api";
import getOpenConversations from "./getOpenConversations";
import getConversation from "./getConversation";

const closeConversation = (convID, convTags) => {
  return getAxios()
    .put(`/Conversation?id=${convID}&tag=${convTags.join(",")}`)
    .then((result) => {
      console.debug("closeConversation result", result.data);
      if (result.data.content === 1) {
        return getAxios()
          .put(`/Conversation?id=${convID}&ended=true`)
          .then((result) => {
            if (result.data.content === 1) {
              return getOpenConversations()
                .then(() => getConversation(convID))
                .then(() => {
                  return true;
                });
            } else {
              return false
            }
          })
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.debug("closeConversation error", err.message);
      return false;
    });
};
export default closeConversation;