import getAxios from "./api";
import store from "../store";
import { SET_CONVERSATION } from "../store/actionTypes";

const getConversation = (convID) => {
  return getAxios()
    .get(`/Conversation?id=${convID}`)
    .then((result) => {
      if (result.data.content && result.data.content.length > 0) {
        let conv = result.data.content[0];

        return store.dispatch({
          type: SET_CONVERSATION,
          args: { id: convID, conv: conv },
        });
      } else {
        return store.dispatch({
          type: SET_CONVERSATION,
          args: { id: convID, conv: null },
        });
      }
    });
};
export default getConversation;
