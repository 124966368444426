import getAxios from "./api";
import store from "../store";
import { SET_ROLES, SET_CONNECTION_ERROR } from "../store/actionTypes";

const getRoles = () => {
  console.debug("getRoles");
  return getAxios()
    .get("/Role")
    .then((result) => {
      if (result.data.content) {
        let map = {};
        result.data.content.forEach(role=>{
          map[role.id] = role;
        })
        store.dispatch({ type: SET_ROLES, args: map });
      } else {
        store.dispatch({ type: SET_ROLES, args: null });
      }
    })
    .catch((err) => {
      store.dispatch({ type: SET_CONNECTION_ERROR, args: true });
    });
};
export default getRoles;
