import React, { useEffect } from "react";
import { connect } from "react-redux";
import AgentListener from "../listeners/agentListener";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { TOP_MENU_HEIGHT } from "../const";
import LeftMenu from "../menus/leftMenu";
import TopMenu from "../menus/topMenu";
import Popups from "../popups";
import Notifications from "../notifications";
import ChangePassword from "../pages/changePassword";

const ConversationsPage = React.lazy(() =>
  import("../pages/conversationsPage")
);
const SettingsPage = React.lazy(() => import("../pages/settingsPage"));
const ReportsPage = React.lazy(() => import("../pages/reportsPage"));

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "fixed",
      top: TOP_MENU_HEIGHT,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
  };
});

const AppRouter = (props) => {
  const classes = useStyles();
  useEffect(() => {
    const listener = new AgentListener();
    listener.listen();
    return () => {
      listener.stop();
    };
  }, []);
  return (
    <Router>
      <TopMenu />
      <LeftMenu />
      <Container
        className={classes.container}
        maxWidth={false}
        disableGutters={true}
      >
        <Switch>
          <Route path="/reports" component={ReportsPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/changePassword" component={ChangePassword} />
          <Route path={["/:convID", "/"]} component={ConversationsPage} />
        </Switch>
      </Container>
      <Popups />
      <Notifications />
    </Router>
  );
};

const mapStateToProps = (store) => {
  return { me: store.me, leftMenuOpen: store.leftMenuOpen };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
