import React, { Suspense } from "react";
import { connect } from "react-redux";
import { SET_LEFT_MENU_OPEN } from "../../store/actionTypes";
import { DRAWER_WIDTH } from "../../const";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, List, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
const LeftMenuContent = React.lazy(() => import("./leftMenuContent"));
const AgentInfoBar = React.lazy(() => import("./agentInfoBar"));
const AgentPerformace = React.lazy(() => import("./agentPerformace"));
const ChangeAgent = React.lazy(() => import("./changeAgent"));

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  activeLink: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const LeftMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { leftMenuOpen } = props;
  return (
    <Drawer
      anchor="left"
      className={classes.drawer}
      variant="temporary"
      open={leftMenuOpen}
      transitionDuration={{ enter: theme.transitions.duration.enteringScreen }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={props.close}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
              <ChevronRightIcon />
            )}
        </IconButton>
      </div>
      <Suspense fallback="...">
        <Divider />
        <List>
          {leftMenuOpen && <AgentInfoBar />}
          {leftMenuOpen && <ChangeAgent />}
          {leftMenuOpen && <AgentPerformace />}
          <Divider />
          {leftMenuOpen && <LeftMenuContent />}
        </List>
      </Suspense>
    </Drawer>
  );
};
const mapStateToProps = (store) => {
  return {
    leftMenuOpen: store.leftMenuOpen,
  };
};
const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    close: () => {
      dispatch({ type: SET_LEFT_MENU_OPEN, args: false });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
