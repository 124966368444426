import getAxios from "./api";
const sendPushToken = (agentID, pushToken) => {
    return getAxios()
        .post(`/AgentPushToken`, {
            agentID,
            pushToken
        })
        .then((result) => {
            console.debug("sendPushToken result", result.data);
        });
};
export default sendPushToken;