import getAxios from "./api";
import sendMessage from "./sendMessage";
import cleanString from "../helpers/cleanString";

const sendFile = (file, convID, fileType) => {
  var formData = new FormData();
  formData.append("file", file);
  if (!formData) {
    alert("not form data");
  }
  getAxios()
    .post(`/File?convID=${convID}`, formData)
    .then((resp) => {
      const fileID = resp.data.content;
      const fileName = cleanString(file.name.toLowerCase()
        .replace("Ğ", "g")
        .replace("Ü", "u")
        .replace("Ş", "s")
        .replace("I", "i")
        .replace("İ", "i")
        .replace("Ö", "o")
        .replace("Ç", "c")
        .replace("ğ", "g")
        .replace("ü", "u")
        .replace("ş", "s")
        .replace("ı", "i")
        .replace("ö", "o")
        .replace("ç", "c")
        .replace(/[^0-9a-z.]+/g, ""));
      const fileLoc = `sorunFile://${fileID}/${fileName}`;
      return sendMessage(convID, fileLoc, fileType);
    });
  return;
}
export default sendFile;
