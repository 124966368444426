import { createStore } from "redux";
import Cookies from "js-cookie";
import {
  SET_ME,
  CLEAR,
  SET_LEFT_MENU_OPEN,
  SET_CURRENT_AGENT,
  SET_OPEN_CONVERSATIONS,
  SET_POOLS,
  SET_AGENTS,
  LOGIN_LOADING,
  SET_TOKEN,
  SET_BACKEND,
  SET_CONNECTION_ERROR,
  SET_POPUP,
  SET_NOTIFICATION,
  SET_VARIABLES,
  SET_CONVERSATION,
  SET_MESSAGES,
  SET_LAST_TYPING,
  SET_EXTERNAL_PAGES,
  SET_ROLES,
  SET_CONV_TAGS,
  SET_CLIENT_TAGS,
  SET_COMPANY,
  SET_CURRENT_STATUS,
  PASSWORD_CHANGE_NEEDED,
  SET_QUICK_ANSWERS,
  SET_QUICK_ANSWER_CATEGORY,
  SET_AGENT_QUICK_FILES,
  SET_QUICK_FILE_CATEGORY,
  SET_AGENT_INFO_BAR,
  SET_AGENT_PERFORMACE,
  SET_AGENT_CONVERSATION_SUMMARY
} from "./actionTypes";

const defaultState = () => ({
  me: null,
  leftMenuOpen: false,
  openConversations: null,
  currentAgent: -1, // Conversation sayfasında conv.'ları listelenen temsilci - Representative whose conversations are listed on the conv. page
  pools: null,
  agents: null,
  loginLoading: true,
  conversations: {},
  clientDTOs: {},
  currentAgents: {},
  currentPools: {},
  backend: Cookies.get("backend") || "api.sorunapp.com",
  secureBackend: Cookies.get("secureBackend") || true,
  connectionError: false,
  popup: null,
  notification: null,
  variables: {},
  messages: {},
  externalPages: null,
  roles: null,
  lastTyping: {},
  convTags: null,
  clientTags: null,
  company: null,
  currentStatus: null,
  myRole: null,
  passwordChangeNeeded: null,
  quickAnswers: null,
  quickAnswerCategory: null,
  quickFiles: null,
  quickFileCategory: null,
  token:
    new URLSearchParams(window.location.search).get("token") ||
    Cookies.get("token") ||
    null,
  agentInfoBar: null,
  agentPerformace: null,
  agentConversationSummary: null
});
const reducer = (state = defaultState(), action) => {
  // console.debug({ ...action });
  switch (action.type) {
    case SET_MESSAGES:
      let newMessages = {};
      newMessages[action.args.convID] = action.args.messages;
      return { ...state, messages: { ...state.messages, ...newMessages } };
    case SET_LAST_TYPING:
      let newLastTyping = {};
      newLastTyping[action.args] = Date.now();
      return {
        ...state,
        lastTyping: { ...state.lastTyping, ...newLastTyping },
      };
    case SET_OPEN_CONVERSATIONS:
      let clientDTOs = {};
      let currentAgents = {};
      let currentPools = {};
      Object.values(action.args).forEach((conv) => {
        clientDTOs[conv.clientID] = conv.clientDTO;
        currentAgents[conv.id] = conv.currentAgent;
        currentPools[conv.id] = conv.poolID;
      });

      return {
        ...state,
        openConversations: Object.keys(action.args),
        conversations: { ...state.conversations, ...action.args },
        currentAgents: {
          ...state.currentAgents,
          ...currentAgents,
        },
        currentPools: {
          ...state.currentPools,
          ...currentPools,
        },
        clientDTO: {
          ...state.clientDTO,
          ...clientDTOs,
        },
      };
    case SET_CONVERSATION:
      let map = {};
      map[action.args.id] = action.args.conv;
      let clientDTO = {};
      let currentAgent = {};
      let currentPool = {};
      if (action.args.conv) {
        clientDTO[action.args.conv.clientID] = action.args.conv.clientDTO;
        currentAgent[action.args.conv.id] = action.args.conv.currentAgent;
        currentPool[action.args.conv.id] = action.args.conv.poolID;
      }
      return {
        ...state,
        conversations: { ...state.conversations, ...map },
        clientDTO: { ...state.clientDTO, ...clientDTO },
        currentAgents: { ...state.currentAgents, ...currentAgent },
        currentPools: { ...state.currentPools, ...currentPool },
      };
    case SET_CURRENT_AGENT:
      return { ...state, currentAgent: action.args, openConversations: [] };
    case SET_LEFT_MENU_OPEN:
      return { ...state, leftMenuOpen: action.args };
    case SET_NOTIFICATION:
      return { ...state, notification: action.args };
    case SET_POPUP:
      return { ...state, popup: action.args };
    case LOGIN_LOADING:
      return { ...state, loginLoading: action.args };
    case SET_CURRENT_STATUS:
      return { ...state, currentStatus: action.args };
    case SET_ME:
      return { ...state, me: action.args };
    case SET_POOLS:
      return { ...state, pools: action.args };
    case SET_AGENTS:
      return { ...state, agents: action.args };
    case SET_TOKEN:
      return { ...state, token: action.args };
    case SET_BACKEND:
      return {
        ...state,
        backend: action.args.backend,
        secureBackend: action.args.secureBackend,
      };
    case SET_CONNECTION_ERROR:
      return { ...state, connectionError: action.args };
    case SET_VARIABLES:
      return { ...state, variables: action.args };
    case SET_EXTERNAL_PAGES:
      return { ...state, externalPages: action.args };
    case SET_ROLES:
      return {
        ...state,
        roles: action.args,
        myRole: action.args && action.args[state.me.roleID],
        myPermissions: (action.args[state.me.roleID] || { permissions: [] })
          .permissions,
        currentStatus:
          state.currentStatus ||
          (action.args && action.args[state.me.roleID].defaultStatus),
      };
    case SET_CONV_TAGS:
      return { ...state, convTags: action.args };
    case SET_CLIENT_TAGS:
      return { ...state, clientTags: action.args };
    case SET_COMPANY:
      return { ...state, company: action.args };
    case SET_AGENT_INFO_BAR:
      return { ...state, agentInfoBar: action.args };
    case SET_AGENT_PERFORMACE:
      return { ...state, agentPerformace: action.args };
    case PASSWORD_CHANGE_NEEDED:
      return { ...state, passwordChangeNeeded: action.args };
    case SET_QUICK_ANSWERS:
      return { ...state, quickAnswers: action.args };
    case SET_QUICK_ANSWER_CATEGORY:
      return { ...state, quickAnswerCategory: action.args }
    case SET_AGENT_QUICK_FILES:
      return { ...state, quickFiles: action.args };
    case SET_QUICK_FILE_CATEGORY:
      return { ...state, quickFileCategory: action.args }
    case SET_AGENT_CONVERSATION_SUMMARY:
      return { ...state, agentConversationSummary: action.args }
    case CLEAR:
      return defaultState();
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
