import React, { useState } from "react";
import { connect } from "react-redux";
import SpinLoading from "../components/spinLoading";
import { useTranslation } from "react-i18next";
import {
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SET_POPUP } from "../store/actionTypes";
import { SHOW_MESSAGE } from "../store/popupTypes";
import closeConversation from "../services/closeConversation";

const useStyles = makeStyles((theme) => {
  return {
    list: {
      maxHeight: "45vh",
      maxWidth: "100%",
      overflow: "scroll",
      display: "flex",
      flexDirection: "row",
    },
    option: {
      width: "100%",
    },
  };
});

const EndConversationPopup = (props) => {
  const classes = useStyles();
  const { tagList, currentTags, showMessage, convID } = props;
  const [tags, setTags] = useState(currentTags);
  const { t } = useTranslation("conversationMenu");

  if (!tags || currentTags === undefined) {
    return <SpinLoading />;
  }

  const onTagChanged = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;
    console.debug("onTagChanged", name, checked);
    if (checked) {
      setTags(tags.concat(name));
    } else {
      setTags(tags.filter((tag) => tag + "" !== name));
    }
    // setPoolID(e.target.value);
  };

  const saveAndCloseAction = () => {
    closeConversation(convID, tags)
      .then((result) => {
        if (result) {
          showMessage(t("closeConversationSuccess"))
        } else
          showMessage(t("closeConversationError"))
      })
      .catch((error) => {
        showMessage(t("closeConversationError") + " : " + error.message)
      })
  };

  return (
    <>
      <DialogTitle>{t("end")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("endConversationPleaseTagText")}
        </DialogContentText>
        <FormGroup aria-label="tags" name="tags" className={classes.list}>
          {Object.values(tagList).map((tag) => {
            return (
              <FormControlLabel
                key={tag.id}
                control={
                  <Checkbox
                    checked={tags.indexOf(tag.id + "") > -1}
                    onChange={onTagChanged}
                    name={tag.id + ""}
                  />
                }
                className={classes.option}
                label={tag.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.handleClose}>
          {t("cancel")}
        </Button>
        <Button color="primary" onClick={saveAndCloseAction}>
          {t("saveAndCloseConv")}
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (store, ownProps) => ({
  tagList: store.convTags,
  convID: ownProps.convID,
  conv: store.conversations[ownProps.convID],
  currentTags:
    store.conversations[ownProps.convID] &&
    (store.conversations[ownProps.convID].tags || []),
  handleClose: ownProps.handleClose,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  showMessage: (message) => {
    dispatch({
      type: SET_POPUP,
      args: {
        type: SHOW_MESSAGE,
        props: { content: message },
      },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndConversationPopup);
