import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import LanguageSelector from "../../components/languageSelector";
import getToken from "../../services/getToken";
import getCaptcha from "../../services/getCaptcha";
import login from "../../services/login";
import { CAPTCHA_REFRESH_INTERVAL } from "../../const";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Link,
} from "@material-ui/core";

const LOGO = "./assets/suite-logo.svg"

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    marginBottom: 20,
    marginTop: 10,
  },
  logoDiv: {
    width: "100%",
    height: 50,
    textAlign: "center",
    marginBottom: 20,
  },
  logoImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  captchaDiv: { textAlign: "left", paddingTop: 10, marginBottom: -10 },
  captchaImg: {
    height: 50,
  },
  linkRight: {
    textAlign: "right",
  },
}));

const LoginForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("loginForm");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [captchaValue, setCaptchaValue] = useState("");
  const [error, setError] = useState(null);

  const refreshCaptcha = () => {
    getCaptcha().then((captcha) => {
      setCaptcha(captcha);
    });
  };

  useEffect(() => {
    refreshCaptcha();
    let interval = setInterval(() => {
      refreshCaptcha();
    }, CAPTCHA_REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const loginAction = () => {
    getToken(username, password, captcha.key, captchaValue, rememberMe)
      .then((token) => {
        login();
      })
      .catch((err) => {
        setError(err.message);
        refreshCaptcha();
      });
  };

  return (
    <>
      <div className={classes.logoDiv}>
        <img
          alt="logo of the company"
          src={LOGO}
          className={classes.logoImage}
        />
      </div>
      <Typography component="h1" variant="h5">
        {t("title")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          loginAction();
        }}
      >
        <Typography variant="overline" color="error">
          {error}
        </Typography>
        <TextField
          required
          label={t("username")}
          name="username"
          fullWidth
          autoComplete="username"
          autoFocus
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          required
          name="password"
          label={t("password")}
          type="password"
          id="password"
          fullWidth
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        {captcha && (
          <div className={classes.captchaDiv}>
            <img
              src={captcha.image}
              className={classes.captchaImg}
              alt="captcha"
            />
          </div>
        )}

        <TextField
          required
          name="captchaVal"
          label={t("captchaVal")}
          id="captchaVal"
          fullWidth
          onChange={(event) => setCaptchaValue(event.target.value)}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={t("rememberMe")}
          onChange={(event) => setRememberMe(event.target.checked)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          {t("submit")}
        </Button>
      </form>
      <Grid container>
        <Grid item xs={6} className={classes.linkLeft}>
          <Link component={RouterLink} to="/forgotPassword" variant="body2">
            {t("forgotPassword")}
          </Link>
        </Grid>
        <Grid item xs={6} className={classes.linkRight}>
          <Link component={RouterLink} to="/connectionSettings" variant="body2">
            {t("connectionSettings")}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <LanguageSelector />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (store) => {
  return { variables: store.variables };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);