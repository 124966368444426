import store from "../store";
import convertServerTime from "../helpers/convertServerTime";
import { PASSWORD_CHANGE_NEEDED } from "../store/actionTypes";

const checkPasswordTimeout = () => {
  const { me, company } = store.getState();

  if (
    company &&
    me &&
    me.lastPasswordChange &&
    company.passwordExpireDay &&
    company.passwordExpireDay > 0 &&
    Date.now() - convertServerTime(me.lastPasswordChange) >
    company.passwordExpireDay * 3600 * 24 * 100
  ) {
    store.dispatch({ type: PASSWORD_CHANGE_NEEDED, args: true });
  } else {
    store.dispatch({ type: PASSWORD_CHANGE_NEEDED, args: false });
  }
};
export default checkPasswordTimeout;
