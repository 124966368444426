import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => {
  return {
    loading: {
      margin: 15,
    },
  };
});

const SpinLoading = () => {
  const classes = useStyles();
  return <CircularProgress color="secondary" className={classes.loading} />;
};
export default SpinLoading;
