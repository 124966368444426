import getAxios from "./api";
import store from "../store";
import { SET_TOKEN } from "../store/actionTypes";
import Cookies from "js-cookie";

const getToken = (username, password, captchaKey, captchaValue, rememberMe) => {
  console.debug('getToken');
  return getAxios()
    .post(`/Login?captchaKey=${captchaKey}&captchaVal=${captchaValue}`, {
      username,
      password,
    })
    .then((result) => {
      if (result.data.content) {
        store.dispatch({ type: SET_TOKEN, args: result.data.content.token });
        if (rememberMe) {
          Cookies.set("token", result.data.content.token);
        }
        return true;
      } else {
        throw new Error(result.data.error);
      }
    });
};
export default getToken;
