import getAxios from "./api";
import store from "../store";
import { SET_EXTERNAL_PAGES, SET_CONNECTION_ERROR } from "../store/actionTypes";

const getExternalPages = () => {
  console.debug("getExternalPages");
  return getAxios()
    .get("/ExternalPage")
    .then((result) => {
      if (result.data.content) {
        let map = {};
        result.data.content.forEach((p) => {
          map[p.id] = p;
        });
        store.dispatch({ type: SET_EXTERNAL_PAGES, args: map });
      } else {
        store.dispatch({ type: SET_EXTERNAL_PAGES, args: null });
      }
    })
    .catch((err) => {
      store.dispatch({ type: SET_CONNECTION_ERROR, args: true });
    });
};
export default getExternalPages;
