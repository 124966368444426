import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import getVariable from "../../services/getVariable";
import Cookies from "js-cookie";
import { NavLink as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { SET_BACKEND } from "../../store/actionTypes";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    marginBottom: 20,
    marginTop: 10,
  },
  captchaDiv: { textAlign: "center", padding: "10px 30px" },
  captchaImg: {
    width: "100%",
  },
  submit: {
    marginTop: 10,
  },
}));

const ConnectionSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("connectionSettings");

  const [secure, setSecure] = useState(!!props.secureBackend);
  const [backend, setBackend] = useState(props.backend);
  const [error, setError] = useState();
  const [success, setSuccess] = useState("");

  const onSubmit = () => {
    getVariable(backend, secure)
      .then((result) => {
        setSuccess("");
        setError("");
        props.updateConnectionSettings(backend, secure);
        setError("");
        setSuccess(t("saved"));
      })
      .catch((err) => {
        setError(t("checkSettings"));
      });
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t("title")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <Typography variant="overline" color="error">
          {error}
        </Typography>
        <Typography variant="overline" color="primary">
          {success}
        </Typography>
        <TextField
          value={backend}
          required
          label={t("backend")}
          name="backend"
          fullWidth
          autoFocus
          onChange={(event) => setBackend(event.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={secure}
              onChange={(event) => setSecure(event.target.checked)}
            />
          }
          label={t("backendSecure")}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("update")}
        </Button>
      </form>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/" variant="body2">
            {t("returnToLogin")}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (store, ownProps) => {
  return {
    secureBackend: store.secureBackend,
    backend: store.backend,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateConnectionSettings: (backend, secureBackend) => {
      Cookies.set("backend", backend);
      Cookies.set("secureBackend", secureBackend);
      dispatch({ type: SET_BACKEND, args: { backend, secureBackend } });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionSettings);
