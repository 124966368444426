import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { SET_CURRENT_AGENT, SET_LEFT_MENU_OPEN } from "../store/actionTypes";
import Autocomplete from '@material-ui/lab/Autocomplete';
import getAgentConversationSummary from "../services/getAgentConversationSummary";
import {
    DialogTitle,
    DialogActions,
    Button,
    Divider,
    TextField,
} from "@material-ui/core";

const ChangeAgentPopup = (props) => {
    const { t } = useTranslation("popup");
    const { handleClose, selectAgent, agents } = props;

    useEffect(() => {
        getAgentConversationSummary()
    }, [])

    if (!agents) {
        return null;
    }

    return (
        <>
            <DialogTitle>{t("changeAgent")}</DialogTitle>
            <Autocomplete
                id="changeAgentPopup"
                options={agents}
                getOptionLabel={(option) =>
                    option.status === "ONLINE"
                        ? "🟢 " + option.name + " " + option.surName + " (" + option.openConversation + ")"
                        : "🔴 " + option.name + " " + option.surName + " (" + option.openConversation + ")"
                }
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={t("search")} variant="outlined" />}
                onChange={(event, newValue) => {
                    if (newValue && newValue.id) {
                        selectAgent(newValue.id);
                    }
                }}
            />
            <Divider />
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </>
    )
}

const mapStateToProps = (store, ownProps) => ({
    agents: Object.values(store.agentConversationSummary.agentConversationSummaryAgentItemList).sort((a, b) => (a.name + a.surName).localeCompare(b.name + b.surName)),
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectAgent: (id) => {
            dispatch({ type: SET_CURRENT_AGENT, args: id });
            dispatch({ type: SET_LEFT_MENU_OPEN, args: false });
            ownProps.handleClose();
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAgentPopup);
