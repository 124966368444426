const END_CONVERSATION = "END_CONVERSATION";
const FORWARD_CONVERSATION = "FORWARD_CONVERSATION";
const CHANGE_POOL_CONVERSATION = "CHANGE_POOL_CONVERSATION";
const SHOW_MESSAGE = "SHOW_MESSAGE";
const SHOW_QUICK_ANSWER = "SHOW_QUICK_ANSWER";
const SHOW_QUICK_FILES = "SHOW_QUICK_FILES";
const SHOW_MORE_INPUT_OPTIONS = "SHOW_MORE_INPUT_OPTIONS";
const FORWARD_AND_CHANGE_POOL_CONVERSATION = "FORWARD_AND_CHANGE_POOL_CONVERSATION";
const SHOW_LOCATION_SHARING = "SHOW_LOCATION_SHARING"
const SHOW_CHANGE_AGENT = "SHOW_CHANGE_AGENT"
export {
  END_CONVERSATION,
  FORWARD_CONVERSATION,
  CHANGE_POOL_CONVERSATION,
  SHOW_MESSAGE,
  SHOW_QUICK_ANSWER,
  SHOW_QUICK_FILES,
  SHOW_MORE_INPUT_OPTIONS,
  FORWARD_AND_CHANGE_POOL_CONVERSATION,
  SHOW_LOCATION_SHARING,
  SHOW_CHANGE_AGENT
};
