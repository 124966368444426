import getAxios from "./api";
import store from "../store";
import { SET_ME, SET_CONNECTION_ERROR } from "../store/actionTypes";

const getMe = () => {
  console.debug("getMe");
  if (!store.getState().token) {
    return new Promise((resolve) => {
      store.dispatch({ type: SET_ME, args: null });
      resolve(null);
    });
  }
  return getAxios()
    .get("/Token")
    .then((result) => {
      if (result.data.content) {
        let me = result.data.content;
        store.dispatch({ type: SET_ME, args: me });
        return me;
      } else {
        store.dispatch({ type: SET_ME, args: null });
        return null;
      }
    })
    .catch((err) => {
      store.dispatch({ type: SET_CONNECTION_ERROR, args: true });
      return null;
    });
};
export default getMe;
