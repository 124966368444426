import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { enUS, trTR } from '@material-ui/core/locale';
import App from "./app";
import store from "./store";
import i18n from "./i18n";
import LineLoading from "./components/lineLoading";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}, enUS, trTR);

ReactDOM.render(
  <Suspense fallback={<LineLoading />}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          {/* <React.StrictMode> */}
          <App />
          {/* </React.StrictMode> */}
        </I18nextProvider>
      </Provider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById("agentPanel")
);
