const SET_ME = "SET_ME"; // SET Current User
const SET_LEFT_MENU_OPEN = "SET_LEFT_MENU_OPEN"; // Set Left Menu Open
const SET_CURRENT_AGENT = "SET_CURRENT_AGENT"; // Set Current Agent for ConversationsList
const SET_OPEN_CONVERSATIONS = "SET_OPEN_CONVERSATIONS"; // Set Open conversation
const SET_POOLS = "SET_POOLS";
const SET_AGENTS = "SET_AGENTS";
const LOGIN_LOADING = "LOGIN_LOADING";
const SET_TOKEN = "SET_TOKEN";
const SET_BACKEND = "SET_BACKEND";
const SET_CONNECTION_ERROR = "SET_CONNECTION_ERROR";
const CLEAR = "CLEAR"; // Convert Redux store to default. Usefull for logout
const SET_POPUP = "SET_POPUP";
const SET_NOTIFICATION = "SET_NOTIFICATION";
const SET_VARIABLES = "SET_VARIABLES";
const SET_CONVERSATION = "SET_CONVERSATION"; // SET a single conversation
const SET_MESSAGES = "SET_MESSAGES";
const SET_LAST_TYPING = "SET_LAST_TYPING";
const SET_EXTERNAL_PAGES = "SET_EXTERNAL_PAGES";
const SET_ROLES = "SET_ROLES";
const SET_CONV_TAGS = "SET_CONV_TAGS";
const SET_CLIENT_TAGS = "SET_CLIENT_TAGS";
const SET_COMPANY = "SET_COMPANY";
const SET_CURRENT_STATUS = "SET_CURRENT_STATUS";
const PASSWORD_CHANGE_NEEDED = "PASSWORD_CHANGE_NEEDED";
const SET_QUICK_ANSWERS = "SET_QUICK_ANSWERS";
const SET_QUICK_ANSWER_CATEGORY = "SET_QUICK_ANSWER_CATEGORY";
const SET_AGENT_QUICK_FILES = "SET_AGENT_QUICK_FILES";
const SET_QUICK_FILE_CATEGORY = "SET_QUICK_FILE_CATEGORY";
const SET_AGENT_INFO_BAR = "SET_AGENT_INFO_BAR";
const SET_AGENT_PERFORMACE = "SET_AGENT_PERFORMACE";
const SET_AGENT_CONVERSATION_SUMMARY = "SET_AGENT_CONVERSATION_SUMMARY"

export {
  SET_CONV_TAGS,
  SET_CLIENT_TAGS,
  SET_ME,
  SET_LEFT_MENU_OPEN,
  CLEAR,
  SET_CURRENT_AGENT,
  SET_OPEN_CONVERSATIONS,
  SET_POOLS,
  SET_AGENTS,
  LOGIN_LOADING,
  SET_TOKEN,
  SET_BACKEND,
  SET_CONNECTION_ERROR,
  SET_POPUP,
  SET_NOTIFICATION,
  SET_VARIABLES,
  SET_CONVERSATION,
  SET_MESSAGES,
  SET_LAST_TYPING,
  SET_EXTERNAL_PAGES,
  SET_ROLES,
  SET_COMPANY,
  SET_CURRENT_STATUS,
  PASSWORD_CHANGE_NEEDED,
  SET_QUICK_ANSWERS,
  SET_QUICK_ANSWER_CATEGORY,
  SET_AGENT_QUICK_FILES,
  SET_QUICK_FILE_CATEGORY,
  SET_AGENT_INFO_BAR,
  SET_AGENT_PERFORMACE,
  SET_AGENT_CONVERSATION_SUMMARY
};
