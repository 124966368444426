import React from "react";
import { connect } from "react-redux";
import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTranslation } from "react-i18next";
import logout from "../../services/logout";
import sendStatus from "../../services/sendStatus";
import statusList from "../../const/statuses";
import { SET_CURRENT_STATUS } from "../../store/actionTypes";

const ProfileMenu = (props) => {
  const { t } = useTranslation("topMenu");
  const { anchor, handleClose, setStatus } = props;
  return (
    <Menu
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      {Object.keys(statusList).map((statusKey) => {
        let status = statusList[statusKey];
        return (
          <MenuItem
            onClick={() => {
              sendStatus(statusKey);
              setStatus(statusKey);
              handleClose();
            }}
            selected={props.currentStatus === statusKey}
          >
            <ListItemIcon>{status.icon}</ListItemIcon>
            <Typography variant="inherit">
              {t(`status-${status.text}`)}
            </Typography>
          </MenuItem>
        );
      })}
      <Divider />
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t("signOut")}</Typography>
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = (store) => ({ currentStatus: store.currentStatus });
const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch({ type: SET_CURRENT_STATUS, args: status }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
