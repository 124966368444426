import React, { useEffect } from "react";
import { connect } from "react-redux";
import login from "../services/login";
import { Container } from "@material-ui/core";
import LineLoading from "../components/lineLoading";
import ConnectionError from "../components/connectionError";
import LoginRouter from "../routers/loginRouter";
import AppRouter from "../routers/appRouter";
import getVariable from "../services/getVariable";
import ChangePassword from "../pages/changePassword";
const App = (props) => {
  useEffect(() => {
    console.debug("app init");
    getVariable().then(() => login());
  }, []);
  if (props.loginLoading) {
    return <LineLoading />;
  }
  if (props.connectionError) {
    return <ConnectionError />;
  }
  if (props.me) {
    const lastPasswordChange = props.agentInfo.lastPasswordChange

    if (props.passwordChangeNeeded || lastPasswordChange === null || lastPasswordChange === undefined) {
      return (
        <Container maxWidth="xs">
          <ChangePassword changePasswordNeeded={true} />
        </Container>
      );
    }
    return <AppRouter />;
  }
  return <LoginRouter />;
};

const mapStateToProps = (store) => {
  return {
    loginLoading: store.loginLoading,
    connectionError: store.connectionError,
    me: !!store.me,
    agentInfo: store.me,
    passwordChangeNeeded: store.passwordChangeNeeded,
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
