import getAxios from "./api";

const getQuickFiles = (quickFiles) => {
  console.debug("getQuickFiles");

  return getAxios()
    .get(`/QuickFiles/${quickFiles.id}/${quickFiles.name}`)
    .then((response) => {
      if (response) {
        return response.request.responseURL
      } else {
        console.log("error")
        return;
      }
    });
};
export default getQuickFiles;
