import store from "../store";
import havePermission from "./havePermission";
import {
  FREEOWNCONVERSATION,
  ASSIGNOWNCONVERSATION,
  OWNFREECONVERSATION,
  ASSIGNFREECONVERSATION,
  ASSIGNOTHERCONVERSATION,
  OWNOTHERCONVERSATION,
  FREEOTHERCONVERSATION,
} from "./permissions";

const canForwardTo = (currentAgent, nextAgent) => {
  currentAgent = parseInt(currentAgent);
  nextAgent = parseInt(nextAgent);

  const { myPermissions, me } = store.getState();
  const meID = me.id;

  if (currentAgent === meID && nextAgent === -1) {
    return havePermission(myPermissions, FREEOWNCONVERSATION);
  } else if (currentAgent === meID) {
    return havePermission(myPermissions, ASSIGNOWNCONVERSATION);
  } else if (currentAgent === -1 && nextAgent === meID) {
    return havePermission(myPermissions, OWNFREECONVERSATION);
  } else if (currentAgent === -1 && nextAgent !== meID) {
    return havePermission(myPermissions, ASSIGNFREECONVERSATION);
  } else if (nextAgent === meID) {
    return havePermission(myPermissions, OWNOTHERCONVERSATION);
  } else if (nextAgent === -1) {
    return havePermission(myPermissions, FREEOTHERCONVERSATION);
  } else {
    return havePermission(myPermissions, ASSIGNOTHERCONVERSATION);
  }
};

export default canForwardTo;
