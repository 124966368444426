import ChatIcon from "@material-ui/icons/Chat";
const DRAWER_WIDTH = 300;
const APP_ICON = ChatIcon;
const TOP_MENU_HEIGHT = 48;
const AXIOS_CONNECTION_TIMEOUT = 20000;
const CAPTCHA_REFRESH_INTERVAL = 1000 * 60;
const CONVERSATION_SOCKET_PING_INTERVAL = 1000 * 30;
const AGENT_SOCKET_PING_INTERVAL = 1000 * 3;
const REFRESH_CONVERSATION_INTERVAL = 1000 * 5;
const AGENT_SOCKET_CONNECTED_INTERVAL = 1000 * 3;
export {
  DRAWER_WIDTH,
  APP_ICON,
  TOP_MENU_HEIGHT,
  CAPTCHA_REFRESH_INTERVAL,
  CONVERSATION_SOCKET_PING_INTERVAL,
  AXIOS_CONNECTION_TIMEOUT,
  AGENT_SOCKET_PING_INTERVAL,
  REFRESH_CONVERSATION_INTERVAL,
  AGENT_SOCKET_CONNECTED_INTERVAL,
};
