import getAxios from "./api";
import store from "../store";
import { SET_QUICK_ANSWERS } from "../store/actionTypes";

const getQuickAnswers = () => {
  console.debug("getQuickAnswers");
  return getAxios()
    .get(`/QuickAnswer`)
    .then((result) => {
      if (result.data.content) {
        return store.dispatch({
          type: SET_QUICK_ANSWERS,
          args: result.data.content,
        });
      } else {
        return store.dispatch({ type: SET_QUICK_ANSWERS, args: {} });
      }
    });
};
export default getQuickAnswers;
