const ENDOWNCONVERSATION = "ENDOWNCONVERSATION";
const ENDOTHERCONVERSATION = "ENDOTHERCONVERSATION";
const FREEOWNCONVERSATION = "FREEOWNCONVERSATION";
const ASSIGNOWNCONVERSATION = "ASSIGNOWNCONVERSATION";
const OWNFREECONVERSATION = "OWNFREECONVERSATION";
const ASSIGNFREECONVERSATION = "ASSIGNFREECONVERSATION";
const ASSIGNOTHERCONVERSATION = "ASSIGNOTHERCONVERSATION";
const OWNOTHERCONVERSATION = "OWNOTHERCONVERSATION";
const FREEOTHERCONVERSATION = "FREEOTHERCONVERSATION";

export {
  ENDOWNCONVERSATION,
  ENDOTHERCONVERSATION,
  FREEOWNCONVERSATION,
  ASSIGNOWNCONVERSATION,
  OWNFREECONVERSATION,
  ASSIGNFREECONVERSATION,
  ASSIGNOTHERCONVERSATION,
  OWNOTHERCONVERSATION,
  FREEOTHERCONVERSATION,
};
