import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import changePassword from "../../services/changePassword";
import { Typography, TextField, Button, Container, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    marginBottom: 20,
    marginTop: 10,
  },
  logoDiv: {
    width: "100%",
    height: 50,
    textAlign: "center",
    marginBottom: 20,
  },
  logoImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  captchaDiv: { textAlign: "left", paddingTop: 10, marginBottom: -10 },
  captchaImg: {
    height: 50,
  },
  linkRight: {
    textAlign: "right",
  },
  changePassword: {
    paddingTop: theme.spacing(5),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  inputFields: {
    marginTop: theme.spacing(1),
  }
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("changePassword");
  const { username, changePasswordNeeded } = props;

  const [password, setPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [error, setError] = useState(
    changePasswordNeeded ? t("changePasswordNeeded") : ""
  );
  const [success, setSuccess] = useState("");
  const changeAction = () => {
    setError("");
    setSuccess("");
    if (newPassword1 !== newPassword2) {
      setError(t("passwordShouldNotBeSame"));
      return;
    }
    if (newPassword1.length < 6) {
      setError(t("minimumPassword"));
      return;
    }
    changePassword(username, password, newPassword1)
      .then((result) => {
        setSuccess(t("passwordChangeSuccess"));
      })
      .catch((err) => {
        if (err.message === "password") {
          setError(t("prevPasswordWrong"));
        } else {
          setError(t("passwordChangeError") + " : " + err.message);
        }
      });
  };

  return (
    <Container maxWidth="xs" className={classes.changePassword}>
      <Typography component="h1" variant="h5">
        {t("title")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          changeAction();
        }}
      >
        <Typography variant="overline" color="error">
          {error}
        </Typography>
        <Typography variant="overline" color="primary">
          {success}
        </Typography>
        <TextField
          required
          label={t("password")}
          name="password"
          fullWidth
          autoFocus
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? "text" : "password"}
          className={classes.inputFields}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          required
          label={t("newPassword1")}
          name="newPassword1"
          fullWidth
          onChange={(event) => setNewPassword1(event.target.value)}
          type={showPassword ? "text" : "password"}
          className={classes.inputFields}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          required
          label={t("newPassword2")}
          name="newPassword2"
          fullWidth
          onChange={(event) => setNewPassword2(event.target.value)}
          type={showPassword ? "text" : "password"}
          className={classes.inputFields}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button type="submit" className={classes.submitButton} fullWidth variant="contained" color="primary">
          {t("submit")}
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = (store, ownProps) => {
  return {
    changePasswordNeeded: ownProps.changePasswordNeeded,
    username: store.me.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
