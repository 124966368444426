import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import sendFile from "../services/sendFile";
import { SHOW_QUICK_FILES, SHOW_LOCATION_SHARING } from "../store/popupTypes";
import { SET_POPUP } from "../store/actionTypes";
import {
    IMAGE,
    FILE,
} from "../subPages/conversation/dialog/messageList/message/types";
import {
    DialogTitle,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    InputLabel,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => {
    return {
        fileUpload: { color: "#000" },
    };
});

const uploadFile = (convID, e) => {
    let file = e.target.files[0];
    if (!file) {
        return;
    }
    let fileType = undefined;
    var ext = file.name.split(".").pop().toLowerCase();
    switch (ext) {
        case "png":
        case "jpg":
        case "jpeg":
            fileType = IMAGE;
            break;
        case "xlsx":
        case "xls":
        case "doc":
        case "docx":
        case "pdf":
            fileType = FILE;
            break;
        default:
            alert("WARNING: wrong file format.");
            return;
    }
    sendFile(file, convID, fileType);
};

const MoreInputOptions = (props) => {
    const { t } = useTranslation("popup");
    const { handleClose, convID, conv } = props;
    const classes = useStyles();
    return (
        <>
            <DialogTitle>{t("moreOptions")}</DialogTitle>
            <List component="nav" aria-label="main mailbox folders">
                <InputLabel>
                    <ListItem button>
                        <ListItemIcon>
                            <AttachFileIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={t("fileUpload")}
                            className={classes.fileUpload}
                        />
                        <input
                            type="file"
                            name="input-name"
                            onChange={(e) => {
                                uploadFile(convID, e);
                                handleClose();
                            }}
                            style={{ display: "none" }}
                            inputRef="fileSelect"
                        />
                    </ListItem>
                </InputLabel>
                <ListItem button onClick={() => {
                    handleClose();
                    props.showLocationSharing(convID, conv);
                }}>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("shareLocation")} />
                </ListItem>
                <ListItem button onClick={() => {
                    handleClose();
                    props.showQuickFiles(convID, conv);
                }}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("quickfiles")} />
                </ListItem>
            </List>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </>
    );
};

const mapStateToProps = (store, ownProps) => ({
    handleClose: ownProps.handleClose,
    conv: store.conversations[ownProps.convID],
    convID: ownProps.convID,
});
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showQuickFiles: (convID, conv) => {
            dispatch({
                type: SET_POPUP,
                args: {
                    type: SHOW_QUICK_FILES,
                    props: { convID: convID, conv: conv }
                }
            })
        },
        showLocationSharing: (convID, conv, setMessage, handleClose) => {
            dispatch({
                type: SET_POPUP,
                args: {
                    type: SHOW_LOCATION_SHARING,
                    props: { convID: convID, conv: conv, setMessage: setMessage }
                }
            })

        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreInputOptions);
