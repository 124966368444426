import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SET_POPUP } from "../store/actionTypes"
import { SHOW_MESSAGE } from "../store/popupTypes"
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitle, DialogActions, Button, TextField } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    searchbar: {
        width: "90%",
        marginLeft: "5%",
    }
}));

const QuickAnswerPopup = (props) => {
    const { quickAnswers, quickAnswerCategory, handleClose, setMessage } = props
    const { t } = useTranslation("popup")
    const classes = useStyles()
    const [value, setValue] = useState({ answer: null, category: null })

    let answerList = []
    let answerCategories = []

    useEffect(() => {
        quickAnswerCategory.forEach((category) => {
            answerCategories.push({
                "categoryId": category.id,
                "categoryName": category.name,
            })
        })
    }, [])

    if (value.category) {
        quickAnswers.forEach((answer) => {
            if (answer.category === value.category.categoryId) {
                answerList.push({
                    "answer": answer.answer,
                })
            }
        })
    }

    return (
        <>
            <DialogTitle>{t("quickAnswers")}</DialogTitle>
            <Autocomplete // Source: https://material-ui.com/components/autocomplete/#playground
                id="qa-category"
                clearOnEscape
                value={value.category}
                onChange={(event, newValue) => {
                    if (newValue && newValue.inputValue) {
                        setValue((prevState) => ({
                            ...prevState,
                            category: newValue.inputValue,
                        }))
                    } else {
                        setValue((prevState) => ({
                            ...prevState,
                            category: newValue,
                        }))
                    }
                }}
                options={answerCategories}
                getOptionLabel={(option) => option.categoryName}
                renderInput={(params) => <TextField {...params} className={classes.searchbar} label={t("selectQuickAnswerCategory")} margin="normal" />}
            />
            { value.category ? (
                <Autocomplete // Source: https://material-ui.com/components/autocomplete/#playground
                    id="qa-answer"
                    clearOnEscape
                    value={value.answer}
                    onChange={(event, newValue) => {
                        if (newValue && newValue.inputValue) {
                            setValue((prevState) => ({
                                ...prevState,
                                answer: newValue.inputValue,
                            }))
                        } else {
                            setValue((prevState) => ({
                                ...prevState,
                                answer: newValue,
                            }))
                        }
                    }}
                    options={answerList}
                    getOptionLabel={(option) => option.answer}
                    renderInput={(params) => <TextField {...params} className={classes.searchbar} label={t("selectQuickAnswer")} margin="normal" />}
                />
            ) : null}
            <DialogActions>
                <Button onClick={handleClose} color="primary">{t("close")}</Button>
                <Button
                    onClick={() => {
                        if (value.answer !== null) {
                            setMessage(value.answer.answer)
                            handleClose()
                        }
                    }}
                    color="primary">{t("select")}
                </Button>
            </DialogActions>
        </>
    )
}

const mapStateToProps = (store, ownProps) => ({
    quickAnswers: store.quickAnswers,
    handleClose: ownProps.handleClose,
    quickAnswerCategory: store.quickAnswerCategory
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    showMessage: (message) => {
        dispatch({
            type: SET_POPUP,
            args: {
                type: SHOW_MESSAGE,
                props: { content: message }
            }
        })
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickAnswerPopup)
