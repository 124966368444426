import axios from "axios";
import store from "../store";
import { AXIOS_CONNECTION_TIMEOUT } from "../const";
let instance = undefined;
let currentBackend = undefined;
let currentToken = undefined;
let currentSecure = undefined;

const initInstance = () => {
  const { backend, token, secureBackend } = store.getState();
  currentBackend = backend;
  currentToken = token;
  currentSecure = secureBackend;
  instance = createInstance(backend, token, secureBackend);
  console.debug("init instance", instance.defaults.baseURL);
};

const createInstance = (backend, token, secureBackend) => {
  console.debug("new Axios", { backend, token, secureBackend });
  return axios.create({
    baseURL: (secureBackend ? "https://" : "http://") + backend,
    timeout: AXIOS_CONNECTION_TIMEOUT,
    headers: {
      token: token,
    },
  });
};
const getAxios = () => {
  return instance;
};
const handleChange = () => {
  let { backend, token, secureBackend } = store.getState();

  if (
    currentBackend !== backend ||
    currentToken !== token ||
    currentSecure !== secureBackend
  ) {
    initInstance();
  }
};
store.subscribe(handleChange);
initInstance();

export default getAxios;
export { createInstance };
