import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import i18n from "../../i18n";
import LanguageSelectorMenu from "./menu";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      marginTop: 15,
    },
  };
});

const LanguageSelector = (props) => {
  const { onlyIcon, menuItem } = props;
  const classes = useStyles();
  const { t } = useTranslation("languageSelector");
  const [menuAnchor, setMenuAnchor] = useState(null);

  const onClick = (e) => setMenuAnchor(e.currentTarget);

  let btn = null;
  if (onlyIcon) {
    btn = (
      <IconButton onClick={onClick} color="inherit">
        <TranslateIcon />
      </IconButton>
    );
  } else if (menuItem) {
    btn = (
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary={t(i18n.language)} />
        <ListItemSecondaryAction button onClick={onClick}>
          <IconButton edge="end" aria-label="delete">
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  } else {
    btn = (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<TranslateIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={onClick}
      >
        {t(i18n.language)}
      </Button>
    );
  }

  return (
    <>
      {btn}
      <LanguageSelectorMenu
        anchor={menuAnchor}
        handleClose={() => {
          setMenuAnchor(null);
        }}
      />
    </>
  );
};
export default LanguageSelector;
