import getAxios from "./api";

const requestPassword = (username, email, phone) => {
  console.debug("requestPassword");

  return getAxios()
    .post("/ForgetPassword", {
      username,
      email,
      phone,
    })
    .then((result) => {
      if (result.data.error) {
        throw new Error(result.data.error);
      } else if (result.data.content) {
        return "OK";
      } else {
        throw new Error("Error");
      }
    });
};
export default requestPassword;
