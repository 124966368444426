import getAxios from "./api";
import store from "../store";
import { SET_QUICK_ANSWER_CATEGORY } from "../store/actionTypes";

const getQuickAnswerCategory = () => {
    console.debug("getQuickAnswerCategory");
    return getAxios()
        .get(`/QuickAnswerCategory`)
        .then((result) => {
            if (result.data.content) {
                return store.dispatch({
                    type: SET_QUICK_ANSWER_CATEGORY,
                    args: result.data.content,
                });
            } else {
                return store.dispatch({ type: SET_QUICK_ANSWER_CATEGORY, args: {} });
            }
        });
};
export default getQuickAnswerCategory;
