import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import requestPassword from "../../services/requestPassword";
import { NavLink as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Link,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    marginBottom: 20,
    marginTop: 10,
  },
  captchaDiv: { textAlign: "center", padding: "10px 30px" },
  captchaImg: {
    width: "100%",
  },
  submit: {
    marginTop: 10,
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("forgotPassword");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onSubmit = () => {
    setError("");
    setSuccess("");
    requestPassword(username, email, phone)
      .then((result) => {
        setSuccess("Requested");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t("title")}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <Typography variant="overline" color="error">
          {error}
        </Typography>
        <Typography variant="overline" color="primary">
          {success}
        </Typography>
        <TextField
          required
          label={t("username")}
          name="username"
          fullWidth
          autoComplete="username"
          autoFocus
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          required
          name="E-mail"
          label="E-mail"
          type="text"
          id="email"
          fullWidth
          autoComplete="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          required
          name="phone"
          label={t("phoneNumber")}
          type="text"
          id="phone"
          fullWidth
          autoComplete="email"
          placeholder={t("phoneNumberFormat")}
          onChange={(event) => setPhone(event.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("submit")}
        </Button>
      </form>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/" variant="body2">
            {t("returnToLogin")}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
export default ForgotPassword;
