import getMe from "./getMe";
import getPools from "./getPools";
import getAgents from "./getAgents";
import getRoles from "./getRoles";
import getConvTags from "./getConvTags";
import getClientTags from "./getClientTags";
import getExternalPages from "./getExternalPages";
import getCompany from "./getCompany";
import checkPasswordTimeout from "./checkPasswordTimeout";
import getQuickAnswers from "./getQuickAnswers";
import getQuickAnswerCategory from "./getQuickAnswerCategory"
import getAgentQuickFiles from "./getAgentQuickFiles";
import getQuickFileCategory from "./getQuickFileCategory"
import getAgentConversationSummary from "./getAgentConversationSummary"
import sendStatus from "./sendStatus";
import sendPushToken from "./sendPushToken";
import store from "../store";
import { SET_CURRENT_AGENT, LOGIN_LOADING } from "../store/actionTypes";

const login = () => {
  console.debug("login");
  return new Promise((resolve, reject) => {
    const { token } = store.getState();
    if (!token) {
      resolve(false);
      return;
    }
    getMe().then((me) => {
      if (me !== null) {
        store.dispatch({ type: SET_CURRENT_AGENT, args: me.id });
        Promise.all([
          getCompany(),
          getPools(),
          getAgents(),
          getExternalPages(),
          getRoles(),
          getConvTags(),
          getClientTags(),
          getQuickAnswers(),
          getQuickAnswerCategory(),
          getAgentQuickFiles(),
          getQuickFileCategory(),
          getAgentConversationSummary(),
        ]).then(() => {
          sendStatus();
          checkPasswordTimeout();
          if (window.AndroidBridge) {
            var pushToken = window.AndroidBridge.getPushToken();
            if (pushToken !== null) {
              sendPushToken(me.id, pushToken);
            }
          }
          if (window.webkit) {
            var message = { 'token': token, "agentId": me.id };
            window.webkit.messageHandlers.derp.postMessage(message);
          }
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  })
    .catch((err) => {
      console.error(err.message);
      return false;
    })
    .then((result) => {
      console.debug("login result", result);
      store.dispatch({ type: LOGIN_LOADING, args: false });
      return result;
    });
};
export default login;
