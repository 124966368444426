import getAxios from "./api";
import getOpenConversations from "./getOpenConversations";
import getConversation from "./getConversation";

const changeConvPool = (convID, poolID) => {
  return getAxios()
    .put(`/Conversation?id=${convID}&pool=${poolID}`)
    .then((result) => {
      console.debug("changeConvPool result", result.data);
      if (result.data.content === 1) {
        return getOpenConversations()
          .then(() => getConversation(convID))
          .then(() => {
            return true;
          });
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.debug("changeConvPool error", err.message);
      return false;
    });
};
export default changeConvPool;
