import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SpinLoading from "../components/spinLoading";
import { useTranslation } from "react-i18next";
import changeConvAgent from "../services/changeConvAgent";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";
import { SET_POPUP } from "../store/actionTypes";
import { SHOW_MESSAGE } from "../store/popupTypes";
import canForwardTo from "../permissions/canForwardTo";

const useStyles = makeStyles((theme) => {
  return {
    list: {
      maxHeight: "55vh",
      maxWidth: "100%",
      overflow: "scroll",
      display: "flex",
      flexDirection: "row",
    }
  };
});

const ForwardConversationPopup = (props) => {
  const classes = useStyles();
  const { agents, currentAgentID, convID, showMessage, poolID, meID } = props;
  const [agentID, setAgentID] = useState(currentAgentID + "");
  const [list, setList] = useState([]);

  const { t } = useTranslation("conversationMenu");
  const toYourselfText = t("toYourself");
  const toPoolText = t("toPool");

  useEffect(() => {
    let l = [];
    if (currentAgentID === meID || canForwardTo(currentAgentID, meID)) {
      l.push({ id: meID + "", label: toYourselfText });
    }
    if (currentAgentID === -1 || canForwardTo(currentAgentID, -1)) {
      l.push({ id: -1 + "", label: toPoolText });
    }
    agents
      .filter((agent) => agent.id !== -1 && agent.id !== meID)
      .forEach((agent) => {
        if (
          currentAgentID === agent.id ||
          canForwardTo(currentAgentID, agent.id)
        ) {
          if (agent.pools.indexOf(poolID) > -1) {
            l.push({
              id: agent.id + "",
              label: agent.name + (agent.surname ? ` ${agent.surname}` : ""),
            });
          }
        }
      });
    setList(l);
  }, [agents, currentAgentID, poolID, meID, toYourselfText, toPoolText]);
  if (!agents || currentAgentID === undefined || !poolID) {
    return <SpinLoading />;
  }

  const saveAction = (e) => {
    changeConvAgent(convID, agentID)
      .then((result) => {
        if (result) {
          showMessage(t("agentChangeSuccess"));
        } else {
          showMessage(t("agentChangeError"));
        }
      })
      .catch((err) => {
        showMessage(t("agentChangeError") + " : " + err.message);
      });
  };
  return (
    <>
      <DialogTitle>{t("changeAgent")}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="agentList"
          options={list}
          getOptionLabel={(option) => option.label}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label={t("changeAgent")} variant="outlined" />
          )}
          onChange={(event, newValue) => {
            if (newValue && newValue.id) {
                setAgentID(newValue.id);
            }
        }}
      className={classes.list}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.handleClose}>
          {t("cancel")}
        </Button>
        <Button color="primary" onClick={saveAction}>
          {t("save")}
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (store, ownProps) => ({
  agents: Object.values(store.agents).map((a) => ({
    id: a.id,
    name: a.name,
    surname: a.surname,
    pools: a.pools,
  })),
  meID: store.me.id,
  convID: ownProps.convID,
  currentAgentID: store.currentAgents[ownProps.convID],
  poolID: store.currentPools[ownProps.convID],
  handleClose: ownProps.handleClose,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  showMessage: (message) => {
    dispatch({
      type: SET_POPUP,
      args: {
        type: SHOW_MESSAGE,
        props: { content: message },
      },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForwardConversationPopup);
