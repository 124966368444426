import getAxios from "./api";
import store from "../store";
const sendStatus = (status = store.getState().currentStatus) => {
  return getAxios()
    .put(`/AgentStatus?status=${status}`)
    .then((result) => {
      console.debug("sendStatus result", result.data);
    });
};
export default sendStatus;
