import getAxios from "./api";
import sendMessage from "./sendMessage";
import store from "../store";
import cleanString from "../helpers/cleanString";

const sendQuickFile = (file, convID, fileType) => {
  const { backend, secureBackend, token } = store.getState();
  
  if (!file) {
    alert("not form data");
  }
  file.convID=convID;
  getAxios()
    .post(`/AgentQuickFiles`, file)
    .then((resp) => {
      const fileID = resp.data.content;
      const fileName = cleanString(file.name.toLowerCase());
      const fileLoc = `${
        secureBackend ? "https://" : "http://"
      }${backend}/File/${fileID}/${fileName}?token=${token}`;
      return sendMessage(convID, fileLoc, fileType);
    });
  return;
};
export default sendQuickFile;