import getAxios from "./api";
import store from "../store";
import { SET_CLIENT_TAGS } from "../store/actionTypes";

const getClientTags = () => {
  console.debug("getClientTag");
  return getAxios()
    .get(`/ClientTag`)
    .then((result) => {
      if (result.data.content) {
        let map = {};
        result.data.content.forEach((a) => {
          map[a.id] = a;
        });
        return store.dispatch({
          type: SET_CLIENT_TAGS,
          args: map,
        });
      } else {
        return store.dispatch({ type: SET_CLIENT_TAGS, args: {} });
      }
    });
};
export default getClientTags;
