import getAxios, { createInstance } from "./api";
import store from "../store";
import { SET_VARIABLES } from "../store/actionTypes";

const getVariable = (backend, secure) => {
  let axios;
  if (backend === undefined || secure === undefined) {
    axios = getAxios();
  } else {
    axios = createInstance(backend, undefined, secure);
  }
  return axios.get("/Variable").then((result) => {
    store.dispatch({ type: SET_VARIABLES, args: result.data.content });
    return result.data.content;
  });
};
export default getVariable;
