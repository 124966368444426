import getAxios from "./api";
import store from "../store";
import { SET_POOLS } from "../store/actionTypes";

const getPools = () => {
  console.debug('getPools');
  return getAxios()
    .get(`/Pool`)
    .then((result) => {
      if (result.data.content) {
        let pools = {};
        result.data.content.forEach(p=>{
          pools[p.id] = p;
        })
        return store.dispatch({
          type: SET_POOLS,
          args: pools,
        });
      } else {
        return store.dispatch({ type: SET_POOLS, args: {} });
      }
    });
};
export default getPools;
