import React from "react";
import i18n from "../../i18n";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Menu";
import Cookies from "js-cookie";

const LanguageSelectorMenu = (props) => {
  const { anchor, handleClose } = props;
  const selectLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("language", lang);
    handleClose();
  };
  return (
    <Popover
      anchorEl={anchor}
      
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          selectLanguage("en");
        }}
      >
        English
      </MenuItem>
      <MenuItem
        onClick={() => {
          selectLanguage("tr");
        }}
      >
        Türkçe
      </MenuItem>
    </Popover>
  );
};

export default LanguageSelectorMenu;
