import React from "react";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import WcIcon from "@material-ui/icons/Wc";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
const statusList = {
  ONLINE: { text: "ONLINE", icon: <ThumbUpAltIcon /> },
  BREAK: { text: "BREAK", icon: <FreeBreakfastIcon /> },
  LUNCH: { text: "LUNCH", icon: <RestaurantIcon /> },
  TRAINING: { text: "TRAINING", icon: <MenuBookIcon /> },
  WC: { text: "WC", icon: <WcIcon /> },
  CALL: { text: "CALL", icon: <PhoneCallbackIcon /> },
  HEALTH: { text: "HEALTH", icon: <LocalHospitalIcon /> },
  MEETING: { text: "MEETING", icon: <PeopleAltIcon /> },
  BUSY: { text: "BUSY", icon: <EventBusyIcon /> },
  OFFLINE: { text: "OFFLINE", icon: <SpeakerNotesOffIcon /> },
};
export default statusList;
