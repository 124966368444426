import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => {
  return {
    loading: {
      marginTop: 5
    },
  };
});

const LineLoading = () => {
  const classes = useStyles();
  return <LinearProgress className={classes.loading} />;
};
export default LineLoading;
