import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const ShowMessagePopup = (props) => {
  const { t } = useTranslation("conversationMenu");
  return (
    <>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">{t("close")}</Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (store, ownProps) => ({
  handleClose: ownProps.handleClose,
  content: ownProps.content,
  title: ownProps.title,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowMessagePopup);
