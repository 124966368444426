import getAxios from "./api";
import store from "../store";
import { SET_QUICK_FILE_CATEGORY } from "../store/actionTypes";

const getQuickFileCategory = () => {
    console.debug("getQuickFilesCategory");
    return getAxios()
        .get(`/QuickFilesCategory`)
        .then((result) => {
            if (result.data.content) {
                return store.dispatch({
                    type: SET_QUICK_FILE_CATEGORY,
                    args: result.data.content,
                });
            } else {
                return store.dispatch({ type: SET_QUICK_FILE_CATEGORY, args: {} });
            }
        });
};
export default getQuickFileCategory;
