import React, { useState } from "react";
import { connect } from "react-redux";
import SpinLoading from "../components/spinLoading";
import { useTranslation } from "react-i18next";
import changeConvPool from "../services/changeConvPool";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { SET_POPUP } from "../store/actionTypes";
import { SHOW_MESSAGE } from "../store/popupTypes";

const useStyles = makeStyles((theme) => {
  return {
    list: {
      maxHeight: "55vh",
      maxWidth: "100%",
      overflow: "scroll",
      display: "flex",
      flexDirection: "row",
    }
  };
});

const ChangePoolConversationPopup = (props) => {
  const classes = useStyles();
  const { pools, currentPoolID, convID, showMessage } = props;
  const [poolID, setPoolID] = useState(currentPoolID + "");
  const { t } = useTranslation("conversationMenu");

  if (!pools || currentPoolID === undefined) {
    return <SpinLoading />;
  }


  const saveAction = (e) => {
    changeConvPool(convID, poolID)
      .then((result) => {
        if (result) {
          showMessage(t("poolChangeSuccess"));
        } else {
          showMessage(t("poolChangeError"));
        }
      })
      .catch((err) => {
        showMessage(t("poolChangeError") + " : " + err.message);
      });
  };

  let poolsList = Object.keys(pools).map(key => {
    return pools[key];
})

return (
    <>
      <DialogTitle>{t("changePool")}</DialogTitle>
      <DialogContent>
      <Autocomplete
          id="pools"
          options={poolsList}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label={t("changeAgent")} variant="outlined" />
          )}
          onChange={(event, newValue) => {
            if (newValue && newValue.id) {
                setPoolID(newValue.id);
            }
        }}
        className={classes.list}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.handleClose}>
          {t("cancel")}
        </Button>
        <Button color="primary" onClick={saveAction}>
          {t("save")}
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (store, ownProps) => ({
  pools: store.pools,
  convID: ownProps.convID,
  currentPoolID:
    store.conversations[ownProps.convID] &&
    store.conversations[ownProps.convID].poolID,
  handleClose: ownProps.handleClose,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  showMessage: (message) => {
    dispatch({
      type: SET_POPUP,
      args: {
        type: SHOW_MESSAGE,
        props: { content: message },
      },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePoolConversationPopup);
