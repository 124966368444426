import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { SET_LEFT_MENU_OPEN } from "../../store/actionTypes";
import { DRAWER_WIDTH, TOP_MENU_HEIGHT } from "../../const";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileMenu from "../profileMenu";
import statusList from "../../const/statuses";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      height: TOP_MENU_HEIGHT,
      overflow: "hidden",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      height: TOP_MENU_HEIGHT,
      overflow: "hidden",
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    profileButton: {
      float: "right",
    },
    appTitle: {
      flexGrow: 1
    },
    appIcon: {
      marginRight: 10,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    },
    hide: {
      display: "none",
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    onlineStatus: {
      color: theme.palette.success.main,
    },
    otherStatus: {
      color: theme.palette.error.main,
    },
    statusText: {
      marginLeft: theme.spacing(1)
    }
  };
});

const TopMenu = (props) => {
  const { t } = useTranslation("topMenu");
  const classes = useStyles();
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const { currentStatus, company } = props;
  if (!currentStatus) return null;

  let companyName // Fetching company name from Company service
  if (company.name) { companyName = company.name }
  
  const profileIcon = statusList[currentStatus].icon;
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.leftMenuOpen,
      })}
    >
      <Toolbar variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.openLeftMenu}
          edge="start"
          className={clsx(
            classes.menuButton,
            props.leftMenuOpen && classes.hide
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          className={classes.appTitle}
        >
          {companyName}
        </Typography>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          edge="end"
          onClick={(e) => {
            setProfileMenuAnchor(e.currentTarget);
          }}
        >
          {profileIcon}
          <Typography
            component="h6"
            className={classes.statusText}
          >
            {t("status-" + currentStatus).toUpperCase()}
          </Typography>
        </IconButton>
        {profileMenuAnchor && (
          <ProfileMenu
            anchor={profileMenuAnchor}
            handleClose={() => setProfileMenuAnchor(null)}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (store) => ({
  leftMenuOpen: store.leftMenuOpen,
  profileMenuOpen: store.profileMenuOpen,
  me: store.me,
  currentStatus: store.currentStatus,
  myRole: store.myRole,
  roles: store.roles,
  company: store.company
});
const mapDispatchToProps = (dispatch, props) => ({
  openLeftMenu: () => {
    dispatch({ type: SET_LEFT_MENU_OPEN, args: true });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
