import getAxios from "./api";
const sendMessage = (convID, payload, type = "TEXT") => {
  return getAxios()
    .post(`/Message?conversation=${convID}&type=${type}`, payload, {
      headers: { "content-type": "application/json" },
    })
    .then((result) => {
      console.debug("sendMessage result", result.data);
    });
};
export default sendMessage;
