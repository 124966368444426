import getAxios from "./api";
import store from "../store";
import { SET_CONV_TAGS } from "../store/actionTypes";

const getConvTags = () => {
  console.debug("getConvTags");
  return getAxios()
    .get(`/ConversationTag`)
    .then((result) => {
      if (result.data.content) {
        let map = {};
        result.data.content.forEach((a) => {
          map[a.id] = a;
        });
        return store.dispatch({
          type: SET_CONV_TAGS,
          args: map,
        });
      } else {
        return store.dispatch({ type: SET_CONV_TAGS, args: {} });
      }
    });
};
export default getConvTags;
