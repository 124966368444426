import getAxios from "./api";
import getOpenConversations from "./getOpenConversations";
import getConversation from "./getConversation";

const changeConvAgent = (convID, agentID) => {
  return getAxios()
    .put(`/Conversation?id=${convID}&currentAgent=${agentID}`)
    .then((result) => {
      console.debug("changeConvAgent result", result.data);
      if (result.data.content === 1) {
        return getOpenConversations()
          .then(() => getConversation(convID))
          .then(() => {
            return true;
          });
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.debug("changeConvAgent error", err.message);
      return false;
    });
};
export default changeConvAgent;
